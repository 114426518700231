import { DATE_FNS, LOCALE_TAG, resources as defaultResources, TAG_TABLE } from '@lib/i18n/resources'
import { O, Publisher } from '@prospective/pms-js-utils'
import { CATEGORIES, Logger } from '@modules/logging/logger'
import { formatters } from './formatters'
import { Languages } from '@structures/languages.js'

const [change, publishChange] = Publisher()
const logger = Logger('Localization', CATEGORIES.MAIN)


export const mergeLocalizationResources = (...resources) =>
    [...resources].reduce((result, pluginResources) => ({
        ...result,
        ...O(pluginResources).map((entries, languageTag) => ({...result[languageTag], ...entries})).valueOf()
    }), {})

export const mergeTagTables = (...tagTables) =>
    [...tagTables].reduce((result, table = []) => {
        table.forEach(entry => {
            const existingEntryIndex = result.findIndex(existing => existing.tag === entry.tag)
            if (existingEntryIndex !== -1)
                result[existingEntryIndex] = entry
            result.push(entry)
        })
        return result
    }, [])

function getResources(languageTag) {
    let entry = localization.tagTable.find((entry) => entry.tag === languageTag)
    if (!entry) return

    const fallbacks = entry.fallbacks || []
    const localeChain = [languageTag, ...fallbacks]
    const getResource = (languageTag) => localization?.resources[languageTag] || {}
    // const mergedResources = mergeLocalizationResources(resources[languageTag], PluginManager.localizationResources)
    // console.debug('mergedResources', mergedResources)
    return localeChain.reduceRight((result, languageTag) => ({ ...result, ...getResource(languageTag) }), {})
}

const getBrowserLocale = () => {
    const currentLocale = localStorage.getItem("current_locale")
    const browserLocale = navigator.language

    if(currentLocale) return findLocaleByTag(currentLocale)
    return findLocaleByTag(browserLocale)
}

const getLocaleFunction = languageTag => {
    const browserLocale = findLocaleByTag(navigator.language)
    const userSpecificLocale = Languages.toArray().find((item) => item.tag === browserLocale.tag)

    if (languageTag && languageTag.split('-').at(0) === browserLocale.tag.split('-').at(0))
        languageTag = userSpecificLocale.tag

    let entry = localization.tagTable.find((entry) => entry.tag === languageTag)
    let resources = getResources(languageTag)

    if (!resources) {
        logger.warn(`Locale ${languageTag} not found. Using ${DEFAULT_SETTING.tag} as fallback.`)
        entry = DEFAULT_SETTING
        resources = getResources(DEFAULT_SETTING.tag)
    }

    return (key, firstParam, ...params) => {
        if (typeof resources[key] === 'function') return resources[key](firstParam, ...params)

        const { tag, fallbacks } = browserLocale
        const tagsToCheck = [tag, ...fallbacks]

        const formatterLocale = tagsToCheck.find(localeTag => {
            const localeFormatters = formatters[localeTag]
            return localeFormatters && localeFormatters.hasOwnProperty(key)
        })

        if (formatterLocale) {
            const selectedFormatter = formatters[formatterLocale][key]
            return selectedFormatter(firstParam, ...params)
        } else {
            let translation = resources[key]
            if (translation === undefined || translation === null) {
                translation = ''
                logger.debug(`No translation found under key '${key}'`)
            }

            return O(firstParam).reduce((text, paramValue, paramName) => text.replace(`{{${paramName}}}`, paramValue), translation)
        }
    }
}

const localeFunction = (key, debugMode, firstParam, ...params) => {
    if(typeof localization.activeResources[key] === 'function') return localization.activeResources[key](firstParam, ...params)

    const { tag, fallbacks } = Localization.browserLocale;
    const tagsToCheck = [tag, ...fallbacks];

    const formatterLocale = tagsToCheck.find(localeTag => {
        const localeFormatters = formatters[localeTag];
        return localeFormatters && localeFormatters.hasOwnProperty(key);
    });

    if (formatterLocale) {
        const selectedFormatter = formatters[formatterLocale][key];
        return selectedFormatter(firstParam, ...params);
    }

    else {
        let translation = localization.activeResources[key]
        if (translation === undefined || translation === null) {
            translation = ''
            logger.debug(`No translation found under key '${key}'`)
        }

        // Being triggered once `translationDebug is activated`
        // The if checks make sure the app doesn't break/throw errors
        if(debugMode) {
            if(typeof key === 'symbol') return translation
            if(key !== 'proAnalytics.rangePickerFormat') return key
        }

        return O(firstParam).reduce((text, paramValue, paramName) => text.replace(`{{${paramName}}}`, paramValue), translation)
    }
}
   

export const DEFAULT_SETTING = {
    tag: 'de-CH',
    language: 'german',
    country: 'switzerland',
}

export const defaultLocalizationState = {
    languageTag: DEFAULT_SETTING.tag,
    languageCode: DEFAULT_SETTING.tag.split("-").at(0),
    language: DEFAULT_SETTING.language,
    country: DEFAULT_SETTING.country,
    resources: defaultResources,
    tagTable: TAG_TABLE,
    activeResources: {},
    fallbacks: [DEFAULT_SETTING.tag],
    debugMode: false,
    text: localeFunction,
    locale: localeFunction,
}

let localization = {...defaultLocalizationState}

const initialize = () => {
    const browserLocale = getBrowserLocale()
    localization.browserLocale =  findLocaleByTag(navigator.language)
    localization.languageTag = browserLocale.tag
    localization.languageCode = localization.languageTag.split('-').at(0)
    localization.language = browserLocale.language
    localization.country = browserLocale.country
    localization.activeResources = getResources(browserLocale.tag)
}


// Set the locale for the specific language tag
function setLocale(languageTag) {
    let langTag = languageTag
    const browserLocale = findLocaleByTag(navigator.language)
    const userSpecificLocale = Languages.toArray().find((item) => item.tag === browserLocale.tag)
    
     if (languageTag && languageTag.split('-')[0] === browserLocale.tag.split('-')[0]) {
        localStorage.setItem('current_locale', userSpecificLocale.tag)
        langTag = userSpecificLocale.tag
     }
     localStorage.setItem('current_locale', langTag)
   

    let entry = localization.tagTable.find((entry) => entry.tag === langTag)
    let resources = getResources(langTag)
    
    if (!resources) {
        logger.warn(`Locale ${langTag} not found. Using ${DEFAULT_SETTING.tag} as fallback.`)
        entry = DEFAULT_SETTING
        resources = getResources(DEFAULT_SETTING.tag)
    }

    localization.activeResources = resources
    localization.languageTag = entry.tag
    localization.languageCode = localization.languageTag.split('-').at(0)
    localization.language = entry.language
    localization.country = entry.country
    // Looks odd, but new function reference helps to detect a locale change:
    localization.locale = function (key, ...params) { return localeFunction(key, localization.debugMode, ...params) }
    localization.text = localization.locale

    publishChange(localization)
}

function setDebugMode(isActive){
    localization.debugMode = isActive
    localization.locale = function(key, ...params) {return localeFunction(key, isActive, ...params)}
    localization.text = function(key, ...params) {return localeFunction(key, isActive, ...params)}
    publishChange(localization)
}

// Find locale
export function findLocale(language, country) {
    if (!language) return DEFAULT_SETTING.tag
    // if (language && !country) TAG_TABLE.find(entry => entry.country === undefined)?.tag
    const entry = localization.tagTable.filter((entry) => entry.language === language).find((entry) => entry.country === country)
    return entry ? entry.tag : localization.tagTable.find((entry) => entry.country === undefined)?.tag
}

/**
 * turns ab-cd to ab-CD
 * @param tag
 * @return {string}
 */
function normalizeLocalTag(tag) {
    const components = tag.split("-")
    if (components.length > 1) components[1] = components[1].toUpperCase()
    return components.join("-")
}

export function findLocaleByTag(localeTag) {
    const localeTagNormalized = normalizeLocalTag(localeTag)
    let entry = localization.tagTable.find((entry) => entry.tag === localeTagNormalized)
    if (entry) return entry

    const languageTag = localeTag.split("-")[0]
    entry = localization.tagTable.find((entry) => entry.tag.startsWith(languageTag))
    if (entry) return entry

    return localization.tagTable.find((entry) => entry.tag === "de-CH")
}

/**
 * Localization module
 * @type {{
 *  readonly country: *,
 *  readonly language: *,
 *  readonly languageTag: *,
 *  readonly locale: function(*, ...[*]): *,
 *  readonly dateFnsLocale: *,
 *  findLocale: ((function(*, *, *, *): (string|*))|*),
 *  readonly text: function(*, *),
 *  readonly state: {country: *, fallbacks: [string|*], resources: undefined|*, language: *, languageTag: *, text: function(*, *): *, locale: function(*, ...[*]): *}, setLocale: setLocale
 *  } & IStatePublisher}
 *  @static {string} LOCALE_TAG
 */
export const Localization = {
    // get resources() { return resources },
    get locale() {
        return localization.locale
    },
    get browserLocale(){
      return localization.browserLocale
    },
    get debugMode(){
        return localization.debugMode
    },
    get dateFnsLocale() {
        return localization.locale[DATE_FNS]
    },
    get language() {
        return localization.language
    },
    get country() {
        return localization.country
    },
    get languageTag() {
        return localization.languageTag
    },
    get languageCode() {
        return localization.languageTag.split("-").at(0)
    },
    get text() {
        return localization.text
    },
    get resources() {
        return localization.resources
    },
    set resources(value) {
        localization.resources = value
        setLocale(localization.languageTag)
    },
    get tagTable() {
        return localization.tagTable
    },
    set tagTable(value) {
        localization.tagTable = value
        setLocale(localization.languageTag)
    },
    get state() {
        return { ...localization }
    },
    setLocale,
    findLocale,
    setDebugMode,
    change,
    ...change,
}

Localization.LOCALE_TAG = LOCALE_TAG
Localization.DATE_FNS = DATE_FNS
Localization.isSameLocale = (locale1, locale2) => {
    return locale1(Localization.LOCALE_TAG) === locale2(Localization.LOCALE_TAG)
}
Localization.getLocaleFunction = getLocaleFunction

initialize()
