import { DatePickerDictionary } from '@components/elements/datepicker/datepicker'
import { endOfToday, startOfMonth } from 'date-fns'
import { KPIMetadata } from './kpi_widget/cockpit_kpis.context.js'
import { View } from '@lib/view_context/view-context.js'
import { Authorization } from '@modules/authorization/authorization.js'
import { Localization } from '@lib/i18n/localization.js'
import { PluginManager } from '@modules/plugins/plugin_manager.js'

export const getDefaultDateRange = () => {
    const today = new Date()
    return {
        from: startOfMonth(today),
        to: endOfToday(),
    }
}

export const careerCenterDefaultActionType = 'UTM'
export const performanceStatisticsDefaultActionType = 'VIEW'

/**
 *
 * @param locale
 * @param permissions
 * @param {ApplicationFeatures} features
 */
export const ProAnalyticsMetadata = (locale, permissions = {}, features) => {
    const performanceViewByOptions = [
        { label: locale('proAnalytics.viewBy.jobs'), value: 'STELLEN' },
        {
            label: locale('proAnalytics.viewBy.fieldOfActivity'),
            value: 'FIELD_OF_ACTIVITY',
        },
    ]

    const utmOption = {
        label: locale('proAnalytics.careerCenterStatisticsActionType.utm'),
        value: 'UTM',
    }

    return {
        pmsFilters: {},
        title: {
            label: locale('proAnalytics.title'),
        },
        dashboardMessage: {
            label: locale('information'),
        },
        recentlyUsedOrganisationNodes: {
            label: locale('recentlyUsed'),
            default: [],
        },
        currency: {
            default: undefined,
            label: 'currencies',
        },
        reports: {
            label: 'reports',
            visible: permissions.reports?.widget?.permissions.read || false,
        },
        reportsStatus: {
            label: locale('status'),
        },
        ...KPIMetadata(locale, permissions),
        kpiReports: {
            visible: permissions.kpi?.permissions.read || false,
        },
        search: {
            label: locale('proAnalytics.search'),
        },
        cancelSearch: {
            actions: ['onTrigger'],
        },
        clearSearch: {
            label: locale('clear'),
            actions: ['onTrigger'],
        },
        dictionariesStatus: {
            label: locale('status'),
        },
        customerConfigStatus: {
            label: locale('status'),
        },
        textFilter: {
            label: locale('proAnalytics.search'),
        },
        searchSuggestions: {
            dictionary: [],
            isLoading: false,
        },
        searchSuggestionsStatus: {
            label: locale('status'),
        },
        activeSearchSuggestions: {
            dictionary: {},
        },
        organizationStructureFilter: {
            label: locale('organizationStructure'),
        },
        recruiterFilter: {
            label: locale('recruiters'),
            dictionary: [],
            getDictionary: undefined,
            isLoading: false,
        },
        recruitersDictionaryStatus: {
            label: locale('status'),
        },
        recruiterKeyword: {
            label: locale('recruiters'),
        },
        mediaFilter: {
            label: locale('media'),
            dictionary: [],
        },
        fieldOfActivityFilter: {
            label: locale('fieldOfActivity'),
            dictionary: [],
        },
        industryFilter: {
            label: locale('industry'),
            dictionary: [],
        },
        atsFilter: {
            label: locale('atsId'),
            dictionary: [],
        },
        internalTitle: {
            label: locale('jobAd.internalTitle'),
        },
        externalTitle: {
            label: locale('jobAd.externalTitle'),
        },
        dateRangeFilter: {
            label: locale('timePeriod'),
            get default() {
                return getDefaultDateRange()
            },
            dictionary: DatePickerDictionary(locale),
        },
        jobsStatistics: {
            label: locale('proAnalytics.jobCountStatistics'),
            default: { stats: [], total: 0 },
            visible: permissions.numberOfPositions?.permissions.read || false,
            description: locale('infoTooltip.jobsCountWidget'),
        },
        jobCountStatistics: {
            label: locale('proAnalytics.jobCountStatistics'),
            default: [],
            visible: permissions.numberOfPositions?.permissions.read || false,
        },
        jobCountStatisticsStatus: {
            label: locale('status'),
        },
        publicationsCountStatistics: {
            default: [],
            visible: permissions.numberOfPositions?.permissions.read || false,
        },
        publicationsCountStatisticsStatus: {
            label: locale('status'),
        },
        performanceStatistics: {
            label: locale('proAnalytics.performanceStatistics'),
            default: [],
            visible: permissions.performance?.permissions.read || false,
            description: locale('infoTooltip.performanceWidget'),
        },
        performanceStatisticsStatus: {
            label: locale('status'),
        },
        performanceStatisticsActionType: {
            label: locale('proAnalytics.performanceStatisticsActionType'),
            default: performanceStatisticsDefaultActionType,
            dictionary: features.proAnalytics?.performanceWidget.actionType.options.inject(),
        },
        performanceStatisticsViewBy: {
            default: performanceViewByOptions.at(0).value,
            dictionary: performanceViewByOptions,
        },
        performanceStatisticsJobFilter: {
            default: null,
        },
        performanceStatisticsActivityFieldFilter: {
            default: null,
        },
        candidateJourneyStatistics: {
            label: locale('proAnalytics.candidateJourneyStatistics'),
            default: [],
            visible: permissions.candidateJourney?.permissions.read || false,
        },
        candidateJourneyStatisticsStatus: {
            label: locale('status'),
        },
        costsPerMediumStatistics: {
            label: locale('proAnalytics.costsPerMediumStatistics'),
            default: [],
            visible: permissions.costsPerMedium?.permissions.read || false,
            description: locale('infoTooltip.costsPerMediumWidget'),
        },
        costsByViews: {
            label: locale('proAnalytics.costsProView'),
        },
        costsByClicks: {
            label: locale('proAnalytics.costsProView'),
        },
        costsByApplications: {
            label: locale('proAnalytics.costsProApplication'),
        },
        costsStatistics: {
            label: locale('proAnalytics.costsStatistics'),
            default: [],
            visible: permissions.costs?.permissions.read || false,
            description: locale('infoTooltip.costsWidget'),
        },
        costsStatisticsStatus: {
            label: locale('status'),
        },
        careerCenterStatistics: {
            label: locale('proAnalytics.careerCenterStatistics'),
            visible: permissions.positionMarket?.permissions.read || false,
        },
        careerCenterStatisticsStatus: {
            label: locale('status'),
        },
        careerCenterStatisticsActionType: {
            label: locale('proAnalytics.careerCenterStatisticsActionType'),
            default: careerCenterDefaultActionType,
            actions: ['onChange'],
            dictionary: [
                utmOption,
                {
                    label: locale('proAnalytics.careerCenterStatisticsActionType.search'),
                    value: 'SEARCH',
                },
                {
                    label: locale('proAnalytics.careerCenterStatisticsActionType.selection'),
                    value: 'SELECTION',
                },
            ],
        },
        careerCenterStatisticsCurrentMediums: {
            label: 'CareerCenter current Mediums',
        },
        resetFilters: {
            label: locale('proAnalytics.resetFilters'),
            actions: ['onTrigger'],
        },
        toggleMoreFilters: {
            label: locale('proAnalytics.lessFilters'),
        },
        reportsLink: {
            actions: ['onTrigger'],
        },
        reportXls: {
            actions: ['onTrigger'],
        },
        reportXlsLoading: {
            label: 'Download Report XLS Loading',
        },
        reportsManagement: {
            label: locale('reports.management'),
            visible: permissions.reports.management.permissions.read,
            actions: ['onTrigger'],
        },
        logo: {
            label: 'Logo',
        },
    }
}

export const ProAnalyticsContext = View.observe(Localization, Authorization, PluginManager).update(
    (localization, authorization, pluginManager) =>
        ProAnalyticsMetadata(localization.locale, authorization.proAnalyticsPermissions, pluginManager.features)
)
