import { formatISODate, haveSameElements, memoizeLastScoped, O } from '@prospective/pms-js-utils'
import { ImgIxService } from '@services/imgix_client.js'
import { CONFIG } from '@configuration/config_variables.js'
import { PluginManager } from '@modules/plugins/plugin_manager.js'

export const STATE_WITH_ACTIVE_ORDERS = 'with_active_orders'
export const STATE_ARCHIVED = 'archived'
export const ACTIVE_ORDERS = 'active'
export const INACTIVE_ORDERS = 'inactive'
export const NEW_ORDER = 'new'
export const LOGO_PLACEHOLDER = {
    hash: CONFIG.DEFAULT_LOGO_HASH,
    url: ImgIxService.buildURL(CONFIG.DEFAULT_LOGO_HASH, {}),
}

export const Order = orderObject => {}
Order.STATE_WITH_ACTIVE_ORDERS = STATE_WITH_ACTIVE_ORDERS
Order.STATE_ARCHIVED = STATE_ARCHIVED
Order.ACTIVE_ORDERS = ACTIVE_ORDERS
Order.INACTIVE_ORDERS = INACTIVE_ORDERS

export const VOUCHER_PERCENTAGE = 'PERCENTAGE'
export const VOUCHER_FIXED = 'FIXED'
export const VoucherType = {}
VoucherType.PERCENTAGE = VOUCHER_PERCENTAGE
VoucherType.FIXED = VOUCHER_FIXED

const prepareLocation = dto => {
    const location = dto.working_location
    let label

    if (location) {
        if (location.zip && location.city) label = `${location.zip}, ${location.city}`
        else if (location.city) label = location.city
        else label = location.region
    }

    return location
        ? {
              ...location,
              label,
          }
        : undefined
}

const filterEmptyValues = object =>
    O(object)
        .filter(value => value !== undefined)
        .valueOf()

/**
 * @typedef {Object} ImageObject
 * @property string [hash]
 * @property string [url]
 * @property string [filename]
 * @property ['LOGO'|'IMAGE'] [type]
 * @property number [width]
 * @property number [height]
 */

/**
 * @typedef {Object} LocationObject
 * @property string [city]
 * @property string [zip]
 * @property string [region]
 * @property string [country]
 */

/**
 * @typedef {Object} JobAdObject
 * @property {string|number} [id]
 * @property {string|number} [postingId]
 * @property {string|number} [templateDataId]
 * @property {string} [jobTitle]
 * @property {(PublicationLanguage.ENGLISH | PublicationLanguage.GERMAN | PublicationLanguage.ITALIAN | PublicationLanguage.FRENCH)[]} [publicationLanguages]
 * @property {string} [owner] Job owner id
 * @property {string} [recruiter] Recruiter id
 * @property {string} [creator] Creator id
 * @property {{ costCenterName: string, costCenterId: string }} [costCenter]
 * @property {Publication.difficultyToFillPosition} [difficultyToFillPosition]
 * @property {{ key: string, value: string }} [employmentType]
 * @property {{ key: string, value: string }} [workplaceModel]
 * @property {string} [introduction]
 * @property {string} [benefits]
 * @property {string} [requirements]
 * @property {string} [tasksLabel]
 * @property {string} [tasks]
 * @property {string} [aboutUs]
 * @property {string} [aboutUs]
 * @property {ImageObject} [logo]
 * @property {ImageObject[]} [images]
 * @property {Object} [imageParameters]
 * @property {'crop'|'contain'|'focalpoint'} [imageParameters.sizing]
 * @property {boolean} [imageParameters.visible]
 * @property {Object[]} [imageParameters.parameters]
 * @property {boolean} [logoVisibility]
 * @property {"left" | "center" | "right"} [logoPosition]
 * @property {LocationObject} [location]
 * @property {string} [additionalInformation]
 * @property {{min: number, max: number}} [workload]
 * @property {[key: string, value: string]} [salaryUnit]
 * @property {number} [salaryMin]
 * @property {number} [salaryMax]
 * @property {string} [applicationEmail]
 * @property {string} [applicationLink]
 * @property {function(updateObject: JobAdObject)} update,
 * @property {function(): Object} toJobDTO,
 * @property {function(): Object} toPostingDTO,
 * @property {function} toSubmitDTO
 * @property {function} toTemplateData
 * @property {function} toSubmitOrSaveDTO
 */

/**
 * Creates a new Job Ad object with default values
 * @returns {JobAdObject}
 */
const createJobAd = () => ({
    id: undefined,
    postingId: undefined,
    jobTitle: undefined,
    companyVideo: undefined,
    externalJobUrl: undefined,
    additionalInformation: undefined,
    publicationLanguages: ['de'],
    owner: undefined,
    recruiter: undefined,
    costCenter: {
        costCenterName: undefined,
        costCenterId: undefined,
    },
    hierarchyNodes: undefined,
    difficultyToFillPosition: undefined,
    introductionContent: undefined,
    benefits: undefined,
    requirements: undefined,
    tasksLabel: undefined,
    tasks: undefined,
    aboutUs: undefined,
    logo: undefined,
    images: undefined,
    imageParameters: undefined,
    logoPosition: 'left',
    logoVisibility: true,
    imageVisibility: true,
    contactNameVisibility: true,
    contactPositionVisibility: true,
    contactPhoneVisibility: true,
    contactEmailVisibility: true,
    contactHomepageVisibility: true,
    contactImageVisibility: true,
    contactAddressVisibility: true,
    applicationEmail: undefined,
    applicationLink: undefined,
    originalJob: undefined,
    cartItems: undefined,
    attributes: {},
    termsAndConditions: undefined,
    pits: undefined,
    cartDateSelection: undefined,
})

/**
 * Converts given jobAd object into a job DTO
 * @param {JobAdObject} jobAd
 * @returns {Object}
 */
const toJobDTO = jobAd => {
    if (!jobAd) return

    const dto = {
        node: jobAd.hierarchyNodes,
        title: jobAd.jobTitle,
        internal_title: jobAd.jobTitle,
        languages: jobAd.publicationLanguages,
    }

    if (jobAd.companyVideo) dto.company_video = jobAd.companyVideo
    if (jobAd.owner) dto.owner_id = jobAd.owner
    if (jobAd.recruiter) dto.recruiter_id = jobAd.recruiter
    if (jobAd.creator) dto.creator_id = jobAd.creator
    if (jobAd.id !== 'new' && jobAd.id !== undefined) dto.id = parseInt(jobAd.id)
    if (jobAd.difficultyToFillPosition) dto.difficulty = jobAd.difficultyToFillPosition
    if (jobAd.costCenter?.costCenterId) dto.cost_center_id = jobAd.costCenter.costCenterId || undefined
    if (jobAd.costCenter?.costCenterName) dto.cost_center = jobAd.costCenter.costCenterName
    if (jobAd.additionalInformation) dto.additional_information = jobAd.additionalInformation
    if (jobAd.workload?.min !== undefined || jobAd.workload?.max !== undefined) dto.workload = jobAd.workload

    if (jobAd.employmentType)
        dto.employment_type = {
            id: jobAd.employmentType,
        }
    if (jobAd.workplaceModel)
        dto.workplace_model = {
            id: jobAd.workplaceModel,
        }
    if (jobAd.salaryMin !== undefined || jobAd.salaryMax !== undefined) {
        dto.salary = {}
        dto.salary.unit = { id: jobAd.salaryUnit, value: jobAd.salaryUnitValue }
        dto.salary.currency = { id: jobAd.currencyValue, value: jobAd.currency }
        if (jobAd?.salaryMin !== undefined) dto.salary.min = jobAd?.salaryMin
        if (jobAd?.salaryMax !== undefined) dto.salary.max = jobAd?.salaryMax
    }

    if (jobAd.location) dto.working_location = jobAd.location
    if (jobAd.externalJobUrl)
        dto.external = {
            url: jobAd.externalJobUrl,
        }

    if (jobAd.selectedBenefits) {
        dto.benefits_list = {}
        dto.benefits_list.items = jobAd.selectedBenefits
    }
    return dto
}

const toSubmitOrSaveDto = jobAd => {
    if (!jobAd) return

    const dto = {
        billing_receiver_id: undefined,
        voucher_id: undefined,
        media_id_to_start_date: undefined,
        application_contact_id: undefined,
        pits: jobAd.pits,
    }

    if (jobAd?.billingReceiver) dto.billing_receiver_id = jobAd?.billingReceiver
    if (jobAd?.voucher) dto.voucher_id = jobAd?.voucher.voucher_id
    dto.media_id_to_start_date = Object.entries(jobAd?.cartItems).reduce((acc, [_, entry]) => {
        acc[entry.medium_id] = jobAd.cartDateSelection
            ? formatISODate(jobAd.cartDateSelection)
            : formatISODate(new Date(entry.next_start_date))
        return acc
    }, {})

    return dto
}

/**
 * Converts given Job DTO into a JobAd object
 * @param dto
 * @returns {JobAdObject}
 */
const fromJobDTO = dto =>
    JobAd({
        id: parseInt(dto.id),
        state: dto.state,
        jobTitle: dto.title || dto.internal_title || undefined,
        companyVideo: dto.company_video || undefined,
        subTitle: dto.additional_information || undefined,
        publicationLanguages: [...new Set(dto.languages)],
        owner: dto.owner_id || undefined,
        creator: dto.creator_id || undefined,
        recruiter: dto.recruiter_id || undefined,
        costCenter: {
            costCenterName: dto.cost_center || undefined,
            costCenterId: dto.cost_center_id || undefined,
        },
        hierarchyNodes: dto.node || undefined,
        difficultyToFillPosition: dto.difficulty || undefined,
        additionalInformation: dto.additional_information || undefined,
        location: prepareLocation(dto),
        employmentType: dto.employment_type?.id || undefined,
        workplaceModel: dto.workplace_model?.id || undefined,
        orders: dto.orders || [],
        workload: {
            min: dto.workload?.min || undefined,
            max: dto.workload?.max || undefined,
        },
        salaryUnit: dto.salary?.unit?.id ? +dto.salary.unit?.id : undefined,
        salaryUnitValue: dto.salary?.unit?.value,
        salaryMin: dto.salary?.min,
        salaryMax: dto.salary?.max,
        externalJobUrl: dto.external?.url,
        originalJob: dto,
        billingReceiver: dto.orders?.at(0)?.billing_receiver,
    })

const toPostingDTO = jobAd => {
    if (!jobAd) return

    const dto = {
        id: jobAd.postingId,
        job_id: jobAd.originalJob?.job_id,
        layout: jobAd.originalJob?.layout,
        owner_id: jobAd.originalJob?.owner_id,
        pitchyou: jobAd.originalJob?.pitchyou,
        preview_url: jobAd.originalJob?.preview_url,
        recruiter_id: jobAd.originalJob?.recruiter_id,
        sparche: jobAd.originalJob?.sparche,
        template_data: jobAd?.originalJob?.template_data,
    }

    if (jobAd.recruiter) dto.recruiter_id = jobAd.recruiter

    if (jobAd.layout?.properties) {
        dto.layout.properties = jobAd.layout.properties
    }

    if (jobAd.jobTitle) {
        dto.template_data.template_data.title = { ...dto.template_data.template_data.title, value: jobAd.jobTitle }
    }

    if (jobAd.companyVideo) {
        dto.template_data.template_data.company_video = {
            ...dto.template_data.template_data.company_video,
            value: jobAd.companyVideo,
        }
    }
    if (jobAd.additionalInformation) {
        dto.template_data.template_data.subtitle = {
            ...dto.template_data.template_data.subtitle,
            value: jobAd.additionalInformation,
        }
    }
    if (jobAd.location) {
        dto.template_data.template_data['location.city'] = {
            ...dto.template_data.template_data['location.city'],
            value: jobAd.location?.city || '',
        }
        dto.template_data.template_data['location.country'] = {
            ...dto.template_data.template_data['location.country'],
            value: jobAd.location?.country || '',
        }
        dto.template_data.template_data['location.placeId'] = {
            ...dto.template_data.template_data['location.placeId'],
            value: jobAd.location?.place_id || '',
        }
        dto.template_data.template_data['location.region'] = {
            ...dto.template_data.template_data['location.region'],
            value: jobAd.location?.region || '',
        }
        dto.template_data.template_data['location.zip'] = {
            ...dto.template_data.template_data['location.zip'],
            value: jobAd.location?.zip || '',
        }
    }
    if (jobAd.employmentType) {
        dto.template_data.template_data.employment_type = {
            ...dto.template_data.template_data.employment_type,
            items: [{ ...dto.template_data.template_data.employment_type.items[0], id: jobAd.employmentType }],
        }
    }
    if (jobAd.workplaceModel) {
        const nextItem = dto.template_data.template_data.workplace_model.element_info.item_list.find(
            listItem => listItem.id === +jobAd.workplaceModel
        )
        const nextValue = nextItem?.value

        dto.template_data.template_data.workplace_model = {
            ...dto.template_data.template_data.workplace_model,
            items: [{ ...nextItem, id: jobAd.workplaceModel }],
            value: nextValue,
        }
    }

    const currencyItem = dto.template_data.template_data['salary.currency']?.element_info?.item_list.find(
        item => item.value === PluginManager?.features?.app?.currency?.inject()
    )

    dto.template_data.template_data['salary.currency'] = {
        ...dto.template_data.template_data['salary.currency'],
        items: [currencyItem],
        value: jobAd.currency,
    }

    if (jobAd?.salaryUnit) {
        const salaryUnitItem = dto.template_data.template_data['salary.unit']?.element_info?.item_list.find(
            item => item.id === jobAd.salaryUnit
        )
        dto.template_data.template_data['salary.unit'] = {
            ...dto.template_data.template_data['salary.unit'],
            items: [salaryUnitItem],
            value: jobAd.salaryUnit,
        }
    }

    if (jobAd?.salaryMin) {
        dto.template_data.template_data['salary.min'] = {
            ...dto.template_data.template_data['salary.min'],
            value: jobAd.salaryMin.toString(),
        }
    }
    if (jobAd?.salaryMax) {
        dto.template_data.template_data['salary.max'] = {
            ...dto.template_data.template_data['salary.max'],
            value: jobAd.salaryMax.toString(),
        }
    }

    if (jobAd?.workload) {
        if (jobAd.workload?.max) {
            dto.template_data.template_data['pensum.max'] = {
                ...dto.template_data.template_data['pensum.max'],
                value: jobAd.workload.max.toString(),
            }
        }
        if (jobAd.workload?.min) {
            dto.template_data.template_data['pensum.min'] = {
                ...dto.template_data.template_data['pensum.min'],
                value: jobAd.workload.min.toString(),
            }
        }
    }

    if (jobAd.publicationLanguages) dto.sprache = jobAd.publicationLanguages?.[0] || 'de'
    if (jobAd.id) dto.job_id = jobAd.id
    if (jobAd.templateDataId) dto.template_data.id = jobAd.templateDataId

    if (jobAd.logo) {
        dto.template_data.template_data.company_logo.items = [
            { id: jobAd.logo.image_pool_id, image: { ...jobAd.logo } },
        ]
    }

    if (jobAd.logoPosition) dto.layout.properties.logo.position = jobAd.logoPosition
    dto.layout.properties.logo.visible = jobAd.logoVisibility

    if (jobAd.imageSizing) dto.layout.properties.image.sizing = jobAd.imageSizing
    if (jobAd.imageParameters) dto.layout.properties.image.params = jobAd.imageParameters
    dto.layout.properties.image.visible = jobAd.imageVisibility

    if (!dto.layout.properties.contact) dto.layout.properties.contact = { name: {}, image: {}, address:{} }
    dto.layout.properties.contact.name.visible = jobAd.contactNameVisibility
    dto.layout.properties.contact.image.visible = jobAd.contactImageVisibility
    dto.layout.properties.contact.address.visible = jobAd.contactAddressVisibility

    if (jobAd.images && jobAd.images.at(0)) {
        if (jobAd.imageSizing === 'crop') {
            dto.template_data.template_data.slider_images = {
                ...dto.template_data.template_data.slider_images,
                items: jobAd.images.map(image => ({
                    id: image.image_pool_id,
                    image,
                })),
            }
        } else
            dto.template_data.template_data.image = {
                ...dto.template_data.template_data.image,
                items: [
                    {
                        id: jobAd.images[0].image_pool_id,
                        image: {
                            ...jobAd.images[0],
                            image_pool_id: jobAd.images[0].image_pool_id,
                        },
                    },
                ],
            }
    }

    if (jobAd.imageVisibility === false) {
        dto.template_data.template_data.slider_images.items = []
        dto.template_data.template_data.image.items = []
    }

    dto.template_data.template_data.introduction.value = jobAd.introductionContent
    if (dto.template_data.template_data.introduction_label)
        dto.template_data.template_data.introduction_label.value = jobAd.introductionHeadline

    dto.template_data.template_data.tasks.value = jobAd.tasksContent
    dto.template_data.template_data.tasks_label.value = jobAd.tasksHeadline

    dto.template_data.template_data.requirements.value = jobAd.requirementsContent
    dto.template_data.template_data.requirements_label.value = jobAd.requirementsHeadline

    dto.template_data.template_data.benefits.value = jobAd.benefitsContent
    dto.template_data.template_data.benefits_label.value = jobAd.benefitsHeadline
    if (dto.template_data.template_data.benefits_list)
        dto.template_data.template_data.benefits_list.items = jobAd.selectedBenefits

    dto.template_data.template_data.application.value = jobAd.applicationContent
    dto.template_data.template_data.contact_label.value = jobAd.applicationHeadline

    dto.template_data.template_data.email.value = jobAd.applicationEmail
    dto.template_data.template_data.apply_link.value = jobAd.applicationLink
    if (jobAd.pitchYou !== undefined) {
        dto.pitchyou = jobAd.pitchYou
    }
    if (jobAd.applicationButtonBackground)
        dto.layout.properties.color.accentBackground = jobAd.applicationButtonBackground
    if (jobAd.applicationButtonBackgroundLight)
        dto.layout.properties.color.accentBackgroundLight = jobAd.applicationButtonBackgroundLight
    if (jobAd.applicationButtonBackgroundDark)
        dto.layout.properties.color.accentBackgroundDark = jobAd.applicationButtonBackgroundDark
    if (jobAd.applicationButtonLabelColor) dto.layout.properties.color.accent = jobAd.applicationButtonLabelColor
    if (jobAd.applicationButtonLabelColor) dto.layout.properties.color.secondaryBackground = jobAd.headlineColor
    dto.layout.properties.workload.visible = jobAd.workloadVisibility
    dto.layout.properties.location.visible = jobAd.locationVisibility
    if (dto.layout.properties.employmentType)
        dto.layout.properties.employmentType.visible = jobAd.employmentTypeVisibility
    if (!dto.layout.properties.workmodel) dto.layout.properties.workmodel = {}
    dto.layout.properties.workmodel.visible = jobAd.workModelVisibility
    if (dto.layout.properties.salary) dto.layout.properties.salary.visible = jobAd.salaryVisibility
    dto.layout.properties.color.primaryBackground = jobAd.primaryBackgroundColor
    dto.layout.properties.color.primary = jobAd.primaryColor

    return dto
}

const toTemplateData = (job, language) => {
    const recruiter = job.contactPersons?.find(contactPerson => contactPerson.id === job.recruiter)
    const priceFormatter = PluginManager?.features?.app?.templatePriceFormatter.inject
    const workplaceLabel = job?.attributes?.[language]?.workplace_model?.find(
        workplace => workplace.id === job.workplaceModel
    )?.value

    return {
        title: job.jobTitle || '',
        subtitle: job.subTitle || '',
        attributes: {
            employmentType: job.attributes[job.publicationLanguages.at(0)]?.employment_type?.find(
                item => item.id === job.employmentType
            )?.label,
            pitchyou: job.pitchYou ? 'fakePitchYouLink' : null,
        },
        location: { ...job.location, city: `${job.location?.zip || ''} ${job.location?.city}` } || {
            street: '',
            city: '',
            zip: '',
            region: '',
            country: '',
        },
        salary: {
            unit: job.salaryUnitLabel,
            min: priceFormatter(job.salaryMin),
            max: priceFormatter(job.salaryMax),
            currency: job?.currency,
        },
        logo: job.logo?.url ? { ...job.logo } : LOGO_PLACEHOLDER,
        images: job.imageSizing === 'crop' ? { headerImages: job.images } : undefined,
        image: job.imageSizing !== 'crop' ? job.images?.at(0) : undefined,
        workload: job.workload,
        workmodel: workplaceLabel,
        introduction: { title: job.introductionHeadline, text: job.introductionContent },
        requirements: { title: job.requirementsHeadline, text: job.requirementsContent },
        tasks: { title: job.tasksHeadline, text: job.tasksContent },
        benefits: { title: job.benefitsHeadline, text: job.benefitsContent, items: job.selectedBenefits },
        aboutUs: { title: job.companyProfileHeadline, text: job.companyProfileContent },
        application: { title: job.applicationHeadline, text: job.applicationContent },
        contact: {
            title: recruiter?.jobTitle?.[language],
            salutation: recruiter?.salutation?.[language],
            firstname: recruiter?.firstName?.[language],
            lastname: recruiter?.lastName?.[language],
            position: recruiter?.position?.[language],
            phone: recruiter?.phone,
            email: recruiter?.email,
            homepage: recruiter?.customAddress?.web,
            image: {
                hash: recruiter?.image?.hash,
                url: recruiter?.image?.url,
            },
            address: {
                companyName: recruiter?.customAddress?.company?.[language],
                street: recruiter?.customAddress?.street?.[language],
                city: recruiter?.customAddress?.place?.[language],
                zip: recruiter?.customAddress?.postalCode,
                country: recruiter?.customAddress?.country,
            },
        },
        company: {
            name: '',
            street: '',
            addressExtra: '',
            zip: '',
            city: '',
            country: '',
            homepage: '',
            ...job.company,
        },
        external: { url: job.externaJoblUrl },
    }
}

const toLayoutProperties = job => {
    return {
        logo: {
            visible: job.logoVisibility,
            position: job.logoPosition,
        },
        image: {
            visible: job.imageVisibility,
            sizing: job.imageSizing,
            params: job.imageParameters,
        },
        color: {
            accent: job.applicationButtonLabelColor,
            accentBackground: job.applicationButtonBackground,
            accentBackgroundLight: job.applicationButtonBackgroundLight,
            accentBackgroundDark: job.applicationButtonBackgroundDark,
            primaryBackground: job.primaryBackgroundColor,
            primary: job.primaryColor,
            secondaryBackground: job.headlineColor,
        },
        workload: {
            visible: job.workloadVisibility,
        },
        employmentType: {
            visible: job.employmentTypeVisibility,
        },
        workmodel: {
            visible: job.workModelVisibility,
        },
        salary: {
            visible: job.salaryVisibility,
        },
        location: {
            visible: job.locationVisibility,
        },
        contact: {
            name: {
                visible: job.contactNameVisibility,
            },
            position: {
                visible: job.contactPositionVisibility,
            },
            phone: {
                visible: job.contactPhoneVisibility,
            },
            email: {
                visible: job.contactEmailVisibility,
            },
            homepage: {
                visible: job.contactHomepageVisibility,
            },
            image: {
                visible: job.contactImageVisibility,
            },
            address: {
                visible: job.contactAddressVisibility,
            }
        },
        description: {
            aboutUs: { visible: job.aboutUsVisibility },
            application: { visible: job.applicationVisibility },
            benefits: { visible: job.benefitsVisibility },
            introduction: { visible: job.introductionVisibility },
            requirements: { visible: job.requirementsVisibility },
            tasks: { visible: job.tasksVisibility },
        },
    }
}

const fromPostingDTO = dto => {
    const templateData = dto.template_data.template_data
    const layout = dto.layout
    const originalJob = dto

    const jobAdObject = {
        id: O(dto).values().at(0).job_id,
        postingId: dto.id,
        logo: templateData?.company_logo.items.at(0)?.image,
        images:
            layout.properties.image.sizing === 'crop'
                ? templateData?.slider_images.items.map(item => item.image)
                : [templateData?.image.items[0]?.image],
        headlineColor: layout.properties.color?.secondaryBackground,
        introductionHeadline: templateData.introduction_label?.value,
        introductionContent: templateData.introduction.value,
        aboutUsVisibility: layout.properties.description?.aboutUs?.visible,
        introductionVisibility: layout.properties.description?.introduction?.visible,
        tasksHeadline: templateData.tasks_label?.value,
        tasksContent: templateData.tasks.value,
        tasksVisibility: layout.properties.description?.tasks?.visible,
        requirementsHeadline: templateData.requirements_label?.value,
        requirementsContent: templateData.requirements.value,
        requirementsVisibility: layout.properties.description?.requirements?.visible,
        benefitsHeadline: templateData.benefits_label?.value,
        benefitsContent: templateData.benefits.value,
        benefitsList: templateData.benefits_list,
        benefitsVisibility: layout.properties.description?.benefits?.visible,
        selectedBenefits: templateData.benefits_list?.items,
        applicationHeadline: templateData.contact_label?.value,
        applicationContent: templateData.application.value,
        applicationVisibility: layout.properties.description?.application?.visible,
        previewUrl: originalJob.preview_url,
        workplaceModel: templateData.workplace_model?.items.at(0)?.id,
        applicationEmail: templateData?.email.value,
        applicationLink: templateData?.apply_link.value,
        pitchYou: dto.pitchyou,
        salaryUnitLabel: templateData?.['salary.unit']?.items?.at(0)?.value,
        handlebarsTemplate: layout.html,
        layoutId: layout.id,
        layoutName: layout.name,
        layoutMessages: layout.messages,
        layoutLanguage: layout.sprache,
        imageParameters: layout.properties.image?.params,
        logoPosition: layout.properties.logo?.position,
        logoVisibility: layout.properties.logo?.visible,
        imageSizing: layout.properties.image?.sizing,
        imageVisibility: layout.properties.image?.visible,
        contactNameVisibility: layout.properties?.contact?.name?.visible,
        contactPositionVisibility: layout.properties?.contact?.position?.visible,
        contactPhoneVisibility: layout.properties?.contact?.phone?.visible,
        contactEmailVisibility: layout.properties?.contact?.email?.visible,
        contactHomepageVisibility: layout.properties?.contact?.homepage?.visible,
        contactImageVisibility: layout.properties?.contact?.image?.visible,
        contactAddressVisibility: layout.properties?.contact?.address?.visible,
        applicationButtonBackground: layout.properties.color?.accentBackground,
        applicationButtonBackgroundLight: layout.properties.color?.accentBackgroundLight,
        applicationButtonBackgroundDark: layout.properties.color?.accentBackgroundDark,
        applicationButtonLabelColor: layout.properties.color?.accent,
        primaryBackgroundColor: layout.properties.color?.primaryBackground,
        primaryColor: layout.properties.color?.primary,
        workloadVisibility: layout.properties.workload?.visible,
        locationVisibility: layout.properties.location?.visible,
        employmentTypeVisibility: layout.properties.employmentType?.visible,
        workModelVisibility: layout.properties.workmodel?.visible,
        salaryVisibility: layout.properties.salary?.visible,
        recruiter: originalJob?.recruiter_id,
        originalJob,
    }
    return filterEmptyValues(jobAdObject)
}

/**
 * Used to compare 2 different JobAd objects. A common use case is to check if user changed something before leaving
 * the edit process. Returns an object with fields that differ and additional method `isEqual` which returns a boolean.
 * @param jobAd1
 * @param jobAd2
 * @returns {{owner: boolean, additionalInformation: boolean, image, creator: boolean, publicationLanguages: *, employmentType: boolean, max: boolean, jobTitle: boolean, isEqual: (function(): this is [string, unknown][]), recruiter: boolean, workload, applicationLink: boolean, unit: boolean, workplaceModel: boolean, min: boolean, hierarchyNodes: boolean, costCenterId: boolean, difficultyToFillPosition: boolean, functionalArea: boolean, applicationEmail: boolean, costCenterName: boolean, logo}}
 */
const compare = (jobAd1 = {}, jobAd2 = {}) => {
    // TODO: Review the compare function and use it
    const result = {
        externalJobUrl: jobAd1.externalJobUrl === jobAd2.externalJobUrl,
        jobTitle: jobAd1.jobTitle === jobAd2.jobTitle,
        companyVideo: jobAd1.companyVideo === jobAd2.companyVideo,
        publicationLanguages: haveSameElements(jobAd1.publicationLanguages, jobAd2.publicationLanguages),
        owner: jobAd1.owner === jobAd2.owner,
        creator: jobAd1.creator === jobAd2.creator,
        recruiter: jobAd1.recruiter === jobAd2.recruiter,
        location: jobAd1.location === jobAd2.location,
        costCenterName: jobAd1.costCenter?.costCenterName === jobAd2.costCenter?.costCenterName,
        costCenterId: jobAd1.costCenter?.costCenterId === jobAd2.costCenter?.costCenterId,
        hierarchyNodes: jobAd1.hierarchyNodes === jobAd2.hierarchyNodes,
        difficultyToFillPosition: jobAd1.difficultyToFillPosition === jobAd2.difficultyToFillPosition,
        additionalInformation: jobAd1.additionalInformation === jobAd2.additionalInformation,
        employmentType: jobAd1.employmentType === jobAd2.employmentType,
        workplaceModel: jobAd1.workplaceModel === jobAd2.workplaceModel,
        workload: jobAd1.workload?.min === jobAd2.workload?.min && jobAd1.workload?.max === jobAd2.workload?.max,
        salaryUnit: jobAd1.salaryUnit === jobAd2.salaryUnit,
        salaryMin: jobAd1.salaryMin === jobAd2.salaryMin,
        salaryMax: jobAd1.salaryMax === jobAd2.salaryMax,
        imageSizing: jobAd1.imageSizing === jobAd2.imageSizing,
        imageParameters: jobAd1.imageParameters === jobAd2.imageParameters,
        logoPosition: jobAd1.logoPosition === jobAd2.logoPosition,
        logoVisibility: jobAd1.logoVisibility === jobAd2.logoVisibility,
        contactImageVisibility: jobAd1.contactImageVisibility === jobAd2.contactImageVisibility,
        contactAddressVisibility: jobAd1.contactAddressVisibility === jobAd2.contactAddressVisibility,
        contactNameVisibility: jobAd1.contactNameVisibility === jobAd2.contactNameVisibility,
        logo:
            jobAd1.logo?.hash === jobAd2.logo?.hash &&
            jobAd1.logo?.url === jobAd2.logo?.url &&
            jobAd1.logo?.filename === jobAd2.logo?.filename &&
            jobAd1.logo?.width === jobAd2.logo?.width &&
            jobAd1.logo?.height === jobAd2.logo?.height,
        image:
            jobAd1.images?.[0]?.hash === jobAd2.images?.[0]?.hash &&
            jobAd1.images?.[0]?.url === jobAd2.images?.[0]?.url &&
            jobAd1.images?.[0]?.filename === jobAd2.images?.[0]?.filename &&
            jobAd1.images?.[0]?.width === jobAd2.images?.[0]?.width &&
            jobAd1.images?.[0]?.height === jobAd2.images?.[0]?.height,
        applicationEmail: jobAd1.applicationEmail === jobAd2.applicationEmail,
        applicationLink: jobAd1.applicationLink === jobAd2.applicationLink,
        pitchYou: jobAd1.pitchYou === jobAd2.pitchYou,
        imageVisibility: jobAd1.imageVisibility === jobAd2.imageVisibility,
        headlineColor: jobAd1.headlineColor === jobAd2.headlineColor,
        introductionHeadline: jobAd1.introductionHeadline === jobAd2.introductionHeadline,
        introductionContent: jobAd1.introductionContent === jobAd2.introductionContent,
        tasksHeadline: jobAd1.tasksHeadline === jobAd2.tasksHeadline,
        tasksContent: jobAd1.tasksContent === jobAd2.tasksContent,
        requirementsHeadline: jobAd1.requirementsHeadline === jobAd2.requirementsHeadline,
        requirementsContent: jobAd1.requirementsContent === jobAd2.requirementsContent,
        benefitsHeadline: jobAd1.benefitsHeadline === jobAd2.benefitsHeadline,
        benefitsContent: jobAd1.benefitsContent === jobAd2.benefitsContent,
        selectedBenefits: jobAd1.selectedBenefits === jobAd2.selectedBenefits,
        applicationHeadline: jobAd1.applicationHeadline === jobAd2.applicationHeadline,
        applicationContent: jobAd1.applicationContent === jobAd2.applicationContent,
        employmentTypeVisibility: jobAd1.employmentTypeVisibility === jobAd2.employmentTypeVisibility,
        workModelVisibility: jobAd1.workModelVisibility === jobAd2.workModelVisibility,
        salaryVisibility: jobAd1.salaryVisibility === jobAd2.salaryVisibility,
        workloadVisibility: jobAd1.workloadVisibility === jobAd2.workloadVisibility,
        locationVisibility: jobAd1.locationVisibility === jobAd2.locationVisibility,
    }
    const isEqual = () => O(result).every(value => value === true)
    return { ...result, isEqual }
}

const createInterfaceProperties = (host, properties) => {
    Object.entries(properties).forEach(([name, value]) => {
        Reflect.defineProperty(host, name, { get: () => value, enumerable: false })
    })
    return host
}

const JobAdType = Symbol('JobAdType')
/**
 *
 * @param jobAd
 * @returns {JobAdObject}
 * @constructor
 * @example
 * const jobAd = JobAd()
 * @example
 * const jobAd = JobAd.fromDTO(dto)
 * const jobAd = JobAd.fromPostingDTO(dto)
 * const dto = jobAd.toDTO()
 * const postingDTO = jobAd.toPostingDTO()
 * @export
 * const jobAd = JobAd()
 * jobAd.jobTitle = 'Test'
 * const dto = jobAd.ToDTO()
 * @export
 * const jobAd = JobAd({
 *     jobTitle: 'Test',
 *     introductionContent: 'Test introduction'
 * })
 * const jobAdUpdate = {
 *     additionalInformation: 'Additional description',
 *     jobTitle: 'Test 2'
 * }
 * const updatedJobAd = jobAd.update(jobAdUpdate)
 * // updatedJobAd = {
 * //    additionalInformation: 'Additional description',
 * //    jobTitle: 'Test 2',
 * //    introductionContent: 'Test introduction'
 * // }
 */
export const JobAd = (jobAd = {}) => {
    if (jobAd[JobAdType] === JobAd) return jobAd
    const jobAdObject = { ...filterEmptyValues(createJobAd()), ...jobAd }
    const toTemplateDataMemo = memoizeLastScoped(toTemplateData)

    const result = {
        ...jobAdObject,
        contactPerson: jobAdObject.contactPersons?.find(contact => contact.id === jobAdObject.recruiter),
    }
    createInterfaceProperties(result, {
        update: updateObject => {
            return JobAd({
                ...jobAdObject,
                ...updateObject,
            })
        },
        language: jobAdObject.publicationLanguages?.at(0),
        isActive: jobAdObject.state === JobAd.STATE_ACTIVE,
        order: jobAd.orders?.at(0),
        orderId: jobAd.orders?.at(0)?.order_id,
        toJobDTO: () => toJobDTO(jobAdObject),
        toPostingDTO: () => toPostingDTO(jobAdObject),
        toSubmitOrSaveDTO: () => toSubmitOrSaveDto(jobAdObject),
        toTemplateData: language => toTemplateDataMemo(jobAdObject, language),
        getLayoutProperties: () => toLayoutProperties(jobAd),
    })
    return result
}

JobAd.fromJobDTO = fromJobDTO
JobAd.fromPostingDTO = fromPostingDTO
JobAd.compare = compare

JobAd.STATE_ACTIVE = 'ACTIVE'
JobAd.STATE_EXPIRED = 'EXPIRED'
JobAd.STATE_INACTIVE = 'INACTIVE'
JobAd.STATE_DRAFT = 'DRAFT'
