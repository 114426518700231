import { memoizeScoped, Publisher } from '@prospective/pms-js-utils'
import { CATEGORIES, Logger } from '@modules/logging/logger.js'
import { Stream, Memoized } from '@prospective/streamliner'
import { RemoteData } from '@lib/remote_data/remote-data.js'
import { tryCatch } from '@prospective/pms-js-utils'
import { UserDetails } from '@modules/user/user.js'
import JobBoosterService from '@services/job_booster_service.js'

const USER_PREFERENCES = 'preferences'
const [onChange, publishChange] = Publisher()

let state = {
    preferences: undefined,
}

// Get entire local storage
const getCurrentStorage = () => {
    let storage = localStorage.getItem(USER_PREFERENCES)
    storage = !storage ? {} : JSON.parse(storage)
    return storage
}

// Get a particular value from local storage
const getStorageValue = (storage, keys) => {
    if (!storage) return
    const [key, ...restOfKeys] = keys
    if (restOfKeys.length === 0) return storage[key]
    return getStorageValue(storage[key], restOfKeys)
}

// Delete a particular key from the storage
const deleteKey = (storage, keyString) => {
    const keys = keyString.split('.')
    if (keys.length === 1) {
        const { [keys[0]]: deleted, ...newStorage } = storage
        return newStorage
    }

    const [currentKey, ...restKeys] = keys
    const currentLevel = storage[currentKey]
    if (!currentLevel) return storage

    if (restKeys.length === 1) {
        const { [restKeys[0]]: deleted, ...newCurrentLevel } = currentLevel
        return {
            ...storage,
            [currentKey]: newCurrentLevel,
        }
    }

    return {
        ...storage,
        [currentKey]: deleteKey(currentLevel, restKeys.join('.')),
    }
}

const logger = Logger('UserPreferences', CATEGORIES.MAIN)
const Storage = (() => {
    const get = key => {
        try {
            const currentStorage = getCurrentStorage()
            if (currentStorage === undefined || Object.keys(currentStorage).length === 0) return

            const k = key.split('.')
            const currentValueFromStorage = getStorageValue(currentStorage, k)

            return currentValueFromStorage
        } catch (error) {
            logger.error(error.message)
        }
    }

    const set = (key, data) => {
        try {
            if (!key) throw new Error('Key missing')
            const keys = key.split('.')
            const currentStorage = getCurrentStorage()

            const updatedStorage = () => {
                const newStorage = { ...currentStorage }
                let currentLevel = newStorage
                for (let i = 0; i < keys.length - 1; i++) {
                    const key = keys[i]
                    currentLevel[key] = currentLevel[key] || {}
                    currentLevel = currentLevel[key]
                }
                currentLevel[keys[keys.length - 1]] = data
                return newStorage
            }

            window.localStorage.setItem(USER_PREFERENCES, JSON.stringify(updatedStorage()))
            state[USER_PREFERENCES] = updatedStorage()
            publishChange(updatedStorage)
            logger.info(`'${key}' has been successfully added to storage`)
        } catch (error) {
            logger.error(error.message)
        }
    }

    const remove = key => {
        try {
            if (!key) throw new Error('Key missing')
            const currentStorage = getCurrentStorage()
            const updatedStorage = deleteKey(currentStorage, key)

            window.localStorage.setItem(USER_PREFERENCES, JSON.stringify(updatedStorage))
            state[USER_PREFERENCES] = updatedStorage
            publishChange(updatedStorage)
            logger.info(`'${key}' has been successfully removed from storage`)
        } catch (error) {
            logger.error(error.message)
        }
    }

    const wipe = () => {
        window.localStorage.clear()
        logger.info('Storage data has been cleared')
    }

    return {
        get,
        set,
        remove,
        wipe,
    }
})()

// Initialize the user preference state
const initialize = () => {
    Object.entries(localStorage).forEach(([key, value]) => {
        if (key === USER_PREFERENCES) {
            const parsedValue = JSON.parse(value)
            state[USER_PREFERENCES] = parsedValue
        }
    })
    publishChange(state)
}

initialize()

const getRecentlyUsedCompany = memoizeScoped(userId => {
    if (!userId) return
    return Storage.get(`recentlyUsedHierarchy.${userId}`)
})

const buildPreferencesStructure = (obj, path, value) => {
    const keys = path.split('.')

    const createNestedObject = (currentObj, key, index) => {
        if (index === keys.length - 1) return { ...currentObj, [key]: value }
        return {
            ...currentObj,
            [key]: createNestedObject(currentObj[key] || {}, keys[index + 1], index + 1),
        }
    }
    return createNestedObject(obj, keys[0], 0)
}

const memoizer = Memoized.getMemoizer()
const getUserPreferences = Stream(async function* () {
    logger.info('Loading user preferences...')
    yield RemoteData.pending()

    const [error, result] = await tryCatch(JobBoosterService.getUserPreferences)()

    if (error) {
        const { logNumber } = logger.error.withError(error, 'Could not load user preferences')
        return RemoteData.error('Keycloak login failed').logNumber(logNumber)
    }

    const currentStorage = getCurrentStorage()
    state.preferences = { ...currentStorage, ...result }

    publishChange(state)
    return RemoteData.setValue(result).success()
}).compose(memoizer)

const setUserPreferences = Stream(async function* (key, nextPreferences) {
    logger.info('Saving user preferences...')
    yield RemoteData.pending()

    const preparePreferences = buildPreferencesStructure(state.preferences, key, nextPreferences)
    const [error] = await tryCatch(JobBoosterService.postUserPreferences)(preparePreferences)

    if (error) {
        const userId = UserDetails.state?.value?.id
        const { logNumber } = logger.error.withError(error, `Could not update user preferences for user #${userId}`)
        return RemoteData.error(locale => locale('settings.personalSettings.error', { logNumber })).logNumber(logNumber)
    }

    state.preferences = buildPreferencesStructure(state.preferences, key, nextPreferences)

    publishChange(state)
    memoizer.invalidate()
    return RemoteData.setValue(state).success()
})

const setPreference = (key, data) =>
    preferenceKeys.includes(key) ? setUserPreferences(key, data) : Storage.set(key, data)

const prepareUserPreferences = (result = {}) => ({
    ...result,
    preferences: result?.preferences ? JSON.parse(result.preferences) : null,
})

export const UserPreferences = {
    set: setPreference,
    get: key => Storage.get(key),
    remove: key => Storage.remove(key),
    wipe: () => Storage.wipe(),
    get state() {
        return state
    },
    get cockpitKPIsDateRange() {
        return Storage.get('cockpit.kpis.dateRange')
    },
    set cockpitKPIsDateRange(value) {
        Storage.set('cockpit.kpis.dateRange', value)
    },
    getRecentlyUsedCompany,
    getUserPreferences,
    prepareUserPreferences,
    ...onChange,
}

UserPreferences.PREFERENCES_COCKPIT_TABLE_CONFIGURATOR = 'preferences.cockpit.jobs_table_configurator'
UserPreferences.PREFERENCES_COCKPIT_TASKS_CONFIGURATOR = 'preferences.cockpit.tasks_table_configurator'
UserPreferences.DEFAULT_SCREEN = 'default_screen'
UserPreferences.DEFAULT_NODE_ID = 'default_node_id'
UserPreferences.LEARNED_ABOUT_PITCHYOU = 'preferences.order.learned_about_pitchyou'
const preferenceKeys = [
    UserPreferences.DEFAULT_SCREEN,
    UserPreferences.DEFAULT_NODE_ID,
    UserPreferences.PREFERENCES_COCKPIT_TABLE_CONFIGURATOR,
    UserPreferences.PREFERENCES_COCKPIT_TASKS_CONFIGURATOR,
    UserPreferences.LEARNED_ABOUT_PITCHYOU,
]
