import { jbGraph3 } from '@styles/default_theme.js'

export const defaultTheme = {
    antd: {
        token: {
            colorPrimary: '#284998',
            colorBgLayout: '#ecf2f5',
            colorBgContainer: '#fff',
            colorBgContainerDisabled: '#dce6ea',
            colorTextDisabled: '#646b7a',
            colorBorder: '#dce6ea',
            borderRadius: 4,
            fontFamily:
                'Montserrat, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
            colorTextPlaceholder: '#474c5a',
            jbColorBase2: '#ecf2f5',
            jbColorBgSecondary: '#dce6ea',
            jbColorBgTertiary: '#bcccd1',
            jbColorBase3: '#b7ccd6',
            jbGraph3: '#90E1BD',
        },
        components: {
            Table: {
                bodySortBg: 'transparent',
                rowHoverBg: '#f9f9f9',
            },
            Checkbox: {
                colorBgContainer: '#ecf2f5',
            },
            Radio: {
                colorBgContainer: '#ecf2f5',
            },
            TreeSelect: {
                colorBgContainer: '#ecf2f5',
            },
            Input: {
                colorBgContainer: '#ecf2f5',
            },
            Select: {
                colorBgContainer: '#ecf2f5',
                multipleItemBg: '#dce6ea',
            },
            Steps: {
                colorTextDisabled: '#dce6ea',
            },
            DatePicker: {
                colorBgContainer: '#ecf2f5',
            },
            Tabs: {
                colorPrimary: '#000000',
                colorPrimaryActive: '#000000',
                inkBarColor: '#284998',
            },
            Tooltip: {
                colorBgSpotlight: '#ffffff',
                colorTextLightSolid: '#474c5a',
            },
            Tag: {
                borderRadiusSM: 50,
                fontSizeSM: 15,
                defaultBg: '#dce6ea',
            },
            Button: {
                fontWeight: 700,
            },
            Typography: {
                fontSize: 14,
            },
            RichTextEditor: {
                colorBgContainer: '#ecf2f5',
            }
        },
    },
}

export const defaultDarkTheme = {
    antd: {
        ...defaultTheme.antd,
        token: {
            ...defaultTheme.antd.token,
            colorPrimary: '#284998',
            colorBgLayout: '#494949',
            colorBgContainer: '#101010',
            colorBgContainerDisabled: '#1b1d1d',
            colorBorder: '#444648',
            borderRadius: 4,
            fontFamily:
                'Montserrat, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
            colorTextPlaceholder: '#474c5a',
            colorTextBase: '#ffffff',
            jbColorBase2: '#171616',
            jbColorBgSecondary: 'rgb(52 52 52)',
            jbColorBgTertiary: '#606465',
            jbColorBase3: '#474a4b',
        },
        components: {
            ...defaultTheme.antd.components,
            Checkbox: {
                colorBgContainer: '#000000',
            },
            Radio: {
                colorBgContainer: '#000000',
            },
            TreeSelect: {
                colorBgContainer: '#000000',
            },
            Input: {
                colorBgContainer: '#000000',
            },
            Select: {
                colorBgContainer: '#000000',
            },
            Steps: {
                colorTextDisabled: 'rgb(52 52 52)',
            },
            DatePicker: {
                colorBgContainer: '#000000',
            },
            Tabs: {
                inkBarColor: '#96ace0',
            },
            Table: {
                rowHoverBg: 'rgb(176, 176, 176)',
            },
            Button: {
                fontWeight: 700,
            },
        },
    },
}
