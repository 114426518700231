import { terminate, useCallback, useEffect, useOnce, useState } from '@prospective/process-router'
import { createPromise, Publisher } from '@prospective/pms-js-utils'
import { createViewContextContainer } from '@prospective/pms-view-context'
import { CATEGORIES, Logger } from '@modules/logging/logger.js'
import { STATUS_ERROR, STATUS_SUCCESS } from '@utils/request_statuses.js'
import { RemoteData } from '@lib/remote_data/remote-data.js'
import { Memoized, MemoizedLast } from '@prospective/streamliner'

export const usePromise = executor => {
    const once = useOnce()

    const [promise, resolve, reject] = once(createPromise)

    executor(resolve, reject)

    return promise
}
/**
 * Returns an interface useful to work with the ViewContext inside a process instance
 * @param {function():ViewContextObject} viewContext
 * @return {ContextContainerInterface}
 */
export const useViewContext = (viewContext) => {
    /** @type ContextContainerInterface */
    const once = useOnce()
    const context = once(() => createViewContextContainer(viewContext))
    const [, setContextState] = useState()

    useEffect(() => {
        context.onDependenciesUpdate.subscribe(setContextState)
        return () => {
            context.onDependenciesUpdate.unsubscribe(setContextState)
            context.destroy()
        }
    }, [])

    return context
}

const logger = Logger('useChildProcess', CATEGORIES.MAIN)
export const useChildProcess = createFunction => {
    const [process, setProcess] = useState()

    useEffect(() => {
        if (!createFunction) return
        let processInstance
        try {
            processInstance = createFunction()
            setProcess(processInstance)
        } catch (e) {
            logger.error.withError(e, `Failed to start process`)
        }
        return () => {
            if (processInstance)
                terminate(processInstance, 'Parent process exit')
        }
    }, [createFunction])

    return process
}

// TODO: Move this hook to the new Process implementation and manage the parent-child relations
//  (sibling processes should not share the same scope variable if it's not defined in parent)
let scopeMap = new Map()
/**
 *
 * @example
 * const ParentProcess = Process(() => {
 *     const [employees, setEmployees] = useScope('employees', ['Daniel', 'Dina'])
 *
 * })
 *
 * const ChildProcess = Process(() => {
 *     const [employees, setEmployees] = useScope('employees')
 *     // employees = ['Daniel', 'Dina']
 *
 *     useEffect(() => {
 *         setEmployees(['Bojan', 'Tomasz'])
 *     }, [])
 * })
 */
export const useScope = (identifier, initialValue) => {
    const [currentValue, setCurrentValue] = useState(scopeMap.get(identifier)?.value || initialValue)

    const getValue = useCallback(() => scopeMap.get(identifier)?.value)

    const setValue = useCallback(value => {
        const entry = scopeMap.get(identifier)
        setCurrentValue(value)
        if (entry && entry.value !== value) {
            entry.value = value
            Array.from(entry.setters)
                .filter(setter => setter !== setValue)
                .forEach(setter => setter(value))
        }
        return value
    })

    useEffect(() => {
        const entry = scopeMap.get(identifier) || { value: initialValue, setters: new Set() }
        scopeMap.set(identifier, entry)
        entry.setters.add(setValue)
        setValue(entry.value)

        return () => {
            entry.setters.delete(setValue)
            if (entry.setters.size === 0)
                scopeMap.delete(identifier)
        }
    }, [identifier])

    return [currentValue, setValue, getValue]
}

export const updateState = (getter, setter) => (key, state) => setter({ ...getter(), [key]: state })

/*export const RemoteData = (remoteData = {}) => {
    const result = {}

    if (Reflect.has(remoteData, 'value')) result.value = remoteData.value
    if (Reflect.has(remoteData, 'status')) result.status = remoteData.status
    if (Reflect.has(remoteData, 'abortController')) result.abortController = remoteData.abortController
    if (Reflect.has(remoteData, 'originalError')) result.originalError = remoteData.originalError
    result.getErrorDetails = locale => result.status?.error ? ({
        title: locale('error'),
        description: typeof result.status?.error?.error === 'function'
            ? result.status.error.error(locale)
            : result.status?.error?.error,
        logNumber: result.status?.error?.logNumber
    }) : undefined

    result.setStatus = status => RemoteData({ ...result, status })
    result.setValue = value => RemoteData({ ...result, value})
    result.abort = abortController => RemoteData({ ...result, abortController })
    result.success = (details = undefined) => RemoteData({...result, status: createSuccessStatus(details)})
    result.pending = (progress = 0, details = undefined) => RemoteData({...result, status: createPendingStatus(progress, details)})
    result.error = error => RemoteData({ ...result, status: createErrorStatus({ error, logNumber: result.status?.error?.logNumber }) })
    result.logNumber = logNumber => RemoteData({ ...result, status: createErrorStatus({ error: result.status?.error?.error, logNumber }) })
    result.cause = originalError => RemoteData({ ...result, originalError })
    result.update = updateObject => {
            if (updateObject?.value === result?.value &&
                updateObject?.status === result?.status &&
                updateObject?.abortController === result?.abortController) return result

            const merged = {
                ...result,
                ...updateObject
            }
            if (updateObject.abortController && result.abortController &&
                result.abortController !== updateObject.abortController &&
                result.status?.status === STATUS_PENDING
            ) {
                result.abortController?.abort()
                merged.aborted = true
            }

            if (result.aborted) {
                merged.value = result.value
                merged.status = result.status
                merged.aborted = false
            }

            if (updateObject.status?.status !== STATUS_PENDING)
                delete merged.abortController

            if (updateObject.status?.status === STATUS_PENDING)
                delete merged.originalError

            return merged
    }

    return result
}

RemoteData.setStatus = status => RemoteData().setStatus(status)
RemoteData.setValue = value => RemoteData().setValue(value)
RemoteData.abort = abortController => RemoteData().abort(abortController)
RemoteData.success = (details = undefined) => RemoteData().success(details)
RemoteData.pending = (progress = 0, details = undefined) => RemoteData().pending(progress, details)
RemoteData.error = error => RemoteData().error(error)
RemoteData.logNumber = logNumber => RemoteData().logNumber(logNumber)
RemoteData.cause = originalError => RemoteData().cause(originalError)*/

// TODO Move out from the process_hooks to remote-data.js
export const retryable = originalStream => {
    let onDataCallbacks = []
    const defaultRetry = () => { console.warn('[retryable] Trying to retry an operation which didn\'t fail. Ignoring.') }
    return function() {
        const [promise, resolve] = createPromise()
        let retry = () => {
            streamInstance = originalStream(...arguments)
            streamInstance.onData(onData)
        }
        const onData = function(originalState) {
            let state = RemoteData(originalState).update({ retry: defaultRetry })
            if (originalState?.status?.status === STATUS_ERROR) {
                const getErrorDetails = locale => {
                    const originalErrorDetails = originalState.getErrorDetails(locale)
                    if (originalErrorDetails)
                        return ({ ...originalErrorDetails, retry })
                    return originalErrorDetails
                }
                state = RemoteData(originalState).update({ retry, getErrorDetails })
            }
            onDataCallbacks.forEach(callback => callback(state))
            if (originalState?.status?.status === STATUS_SUCCESS)
                resolve(state)
        }

        promise.onData = callback => {
            onDataCallbacks.push(callback)
            return promise
        }
        let streamInstance = originalStream(...arguments)
        streamInstance.onData(onData)
        return promise
    }
}

export const useRemoteData = initialValue => {
    const [remoteDataObject, setRemoteDataObject] = useState(RemoteData({ value: initialValue }))
    const setRemoteData = useCallback(remoteData => {
        if (!remoteData) return remoteDataObject
        const result = RemoteData(remoteDataObject).update(remoteData)
        setRemoteDataObject(result)
        return result
    })
    return [
        remoteDataObject,
        setRemoteData
    ]
}

/**
 *
 * @param {StreamDefinition} stream
 * @param [initialState]
 * @returns {[StreamDefinition, RemoteDataObject, currentStateValue: any]}
 */

export const useStream = (stream, initialState) => {
    const [streamFunction, setStreamFunction] = useState()
    const [streamState, setStreamState] = useState(initialState)

    const setState = useCallback(state => {
        setStreamState(state)
    })

    const getStream = useCallback(function()  {
        const args = Array.from(arguments)
        const streamCreator = streamFunction || stream
        return streamCreator(...args)
    })

    useEffect(() => {
        if (!streamFunction) {
            setStreamFunction(stream)
            stream.subscribe(setState)
        }
        return () => streamFunction?.unsubscribe(setStreamState)
    }, [])

    return [getStream, streamState]
}

export const useRemoteDataStream = stream => {
    const [streamFunction, setStreamFunction] = useState()
    const [streamState, setStreamState] = useState(RemoteData())

    const getValue = useCallback(async function()  {
        const args = Array.from(arguments)
        const streamCreator = streamFunction || stream
        try {
            const result = await streamCreator(...args)
            return result.value
        } catch (error) {
            setStreamState(error)
            throw error
        }
    })

    useEffect(() => {
        if (!streamFunction) {
            setStreamFunction(stream)
            stream.subscribe(setStreamState)
        }
        return () => streamFunction?.unsubscribe(setStreamState)
    }, [])

    return [getValue, streamState]
}

export const valueOf = streamStatePublisher => {
    const [state, setState] = useState()

    useEffect(() => {
        streamStatePublisher.subscribe(setState)
        return () => streamStatePublisher.unsubscribe(setState)
    }, [])

    return state?.value
}

export const StreamState = (initialState = RemoteData()) => {
    let state = initialState
    const [update, publishUpdate] = Publisher()
    const setState = stateUpdate => {
        state = stateUpdate
        publishUpdate(state)
    }
    return [
        {
            get state() { return state },
            ...update
        },
        setState,
    ]
}

export const useChange = (initialValues = []) => {
    const [lastValues, setLastValues] = useState(initialValues || [])
    const change = useCallback(function() {
        const dependencies = Array.from(arguments)
        const didDependenciesChange = dependencies.length > lastValues
            || dependencies.some((dependency, index) => dependency !== lastValues.at(index))
        if (didDependenciesChange)
            setLastValues(dependencies)
        return didDependenciesChange
    })
    change.reset = useCallback(() => {
        setLastValues([])
    })
    return change
}

export const useMemoized = () => {
    const [memoizedLast] = useState(Memoized.getMemoizer())
    useEffect(() => {
        return () => {
            memoizedLast.invalidate()
        }
    }, [])
    return memoizedLast
}

export const useMemoizedLast = () => {
    const [memoizedLast] = useState(MemoizedLast.getMemoizer())
    useEffect(() => {
        return () => {
            memoizedLast.invalidate()
        }
    }, [])
    return memoizedLast
}
