import { distinct, O, tryCatch } from '@prospective/pms-js-utils'
import * as xlsx from 'xlsx'
import { buildSuggestionId, getSearchFields } from '@views/pro_analytics/pro_analytics_utils.js'
import JobBoosterService from '@services/job_booster_service.js'
import { slugifyText } from '@utils/string_utils.js'

let queriedReportsAbortController

const abortAndCreateNewController = currentController => {
    if (currentController) currentController.abort()
    return new AbortController()
}

/**
 * ? If reports data is empty what do we do ? We would display a message or an empty XLS
 */

export const getReports = async (reports, reportId, params) => {
    queriedReportsAbortController = abortAndCreateNewController(queriedReportsAbortController)

    const report = reports.find(report => report.id === +reportId)

    if (!report || !reportId) return

    const [error, data] = await tryCatch(JobBoosterService.generateReport)(
        params,
        queriedReportsAbortController.signal,
        report
    )

    return [error, data]
}

const mergeArrToJSON = (a, b) =>
    a.map((item, i) => ({ [item.toLowerCase()]: b[i] })).reduce((json, val) => Object.assign({}, json, val))

const getAggregation = (reportData = []) => {
    const reportDataHeaders = reportData?.header?.map(header => header.replace(/,/g, ''))
    const dataSetLength = reportData?.data[0]?.length
    const exampleDataSet = []

    //we need to identify to content type of each column. but we cannot just pick the first (or any other) record because it could contain a null value in a column.
    //therefore find a value that is not null for each column if possible (exampleDataSet).
    for (let i = 0; i < dataSetLength; i++) {
        const nonNullValues = reportData.data.filter(d => d[i] !== null)
        nonNullValues.length > 0 ? exampleDataSet.push(nonNullValues[0][i]) : exampleDataSet.push(null)
    }

    //now we use the exampleDataSet to determine the content type
    const format = exampleDataSet.map(d => (d === null || isNaN(d) ? 'string' : 'number'))

    const columns = reportDataHeaders.map(headItem => ({
        title: headItem.toLowerCase(),
    }))

    const dataSource = reportData?.data?.reduce((obj, report) => {
        obj.push(mergeArrToJSON(reportDataHeaders, report))
        return obj
    }, [])

    return {
        columns,
        dataSource,
        format,
    }
}

const getReportName = (report, locale) =>
    ({
        de: report.nameDe,
        en: report.nameEn,
        fr: report.nameDe,
        it: report.nameIt,
    })[locale] || report.nameDe

export const getXlsReport = async (data = [], report, dateRange, locale) => {
    const { columns, dataSource, format } = getAggregation(data)
    const { from, to } = dateRange

    const fileName = `${slugifyText(getReportName(report, locale))}_${locale('dateFormatter', from)}-${locale('dateFormatter', to)}`

    const workbook = xlsx.utils.book_new()
    const worksheet = xlsx.utils.json_to_sheet(dataSource, {
        header: columns.map(column => column.title),
    })

    const columnLetters = format.map((f, i) => String.fromCharCode(i + 65)) // 1+64=A, 2+64=B, ... +1 for zero based array

    data.data.forEach((row, rowIndex) => {
        format.forEach((f, i) => {
            if (f === 'number') {
                worksheet[columnLetters[i] + (rowIndex + 2)].t = 'n'
            }
        })
    })

    xlsx.utils.book_append_sheet(workbook, worksheet, 'Report details')
    xlsx.writeFile(workbook, fileName + '.xlsx')
}

export const formatSearchSuggestion = (searchResult, searchTerm, locale, searchLimit) => {
    const groupByMatch = (result = { results: [] }, searchTerm = '', locale, searchLimit) => {
        const fields = [...new Set(result.results?.map(item => item.match))]

        const SEARCH_FIELDS = getSearchFields(locale)
        const groupedResult = fields.reduce((grouped, field) => {
            const fieldMatch = result.results.filter(item => item.match === field)
            const fieldName = O(SEARCH_FIELDS)
                .find(descriptor => descriptor.key === field)
                ?.valueOf().label
            const suggestions = distinct(fieldMatch, (item1, item2) => item1[field] === item2[field])
                .slice(0, 5)
                .map(suggestion => ({
                    label: suggestion[suggestion.match],
                    searchTerm,
                    value: buildSuggestionId(suggestion),
                    ...suggestion,
                }))
            const hitCount = suggestions.length < searchLimit ? suggestions.length : searchLimit + '+'
            let label
            if (hitCount !== undefined)
                label = locale('proAnalytics.search.termInColumnWithHitCount', {
                    searchTerm,
                    fieldName,
                    hitCount,
                })
            else
                label = locale('proAnalytics.search.termInColumn', {
                    searchTerm,
                    fieldName,
                })

            const value = `${field}-${label}`
            grouped.push({ group: field, field, value, searchTerm, label, hitCount, suggestions })

            return grouped
        }, [])
        return {
            searchTerm,
            hitCount: result.hit_count,
            suggestions: searchTerm.length
                ? groupedResult.filter(
                      item =>
                          item.group !== SEARCH_FIELDS.atsId.key &&
                          item.group !== SEARCH_FIELDS.orderId.key &&
                          item.group !== SEARCH_FIELDS.publicationId.key &&
                          item.group !== SEARCH_FIELDS.postingId.key
                  )
                : [],
        }
    }
    return groupByMatch(searchResult, searchTerm, locale, searchLimit)
}
