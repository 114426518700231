import { Card as AntCard } from 'antd'
import './card.scss'
import React from 'react'

const Card = ({ className = 'jbCard', children, ...props }) => {
    return (
        <AntCard className={`Card ${className}`} {...props}>
            {children}
        </AntCard>
    )
}

export default Card
