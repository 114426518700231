export const Languages = {
    enUS: { language: "english", country: "usa", code: 'en', tag: "en-US", fallbacks: ["en-GB","de-CH", "de-CH"] },
    enGB: { language: "english", country: "greatBritain", code: 'en', tag: "en-GB", fallbacks: ["en-US", "de-CH"] },
    enAU: { language: "english", country: "australia", code: 'en', tag: "en-AU", fallbacks: ["en-US", "de-CH"] },
    deDE: { language: "german", country: "germany", code: 'de', tag: "de-DE", fallbacks: ["de-CH", "en-GB"] },
    deAT: { language: "german", country: "austria", code: 'de', tag: "de-AT", fallbacks: ["de-DE", "en-GB"] },
    deCH: { language: "german", country: "switzerland", code: 'de', tag: "de-CH", fallbacks: ["de-DE", "en-GB"] },
    gswCH: { language: "swissGerman", country: "switzerland", code: 'de', tag: "gsw_CH", fallbacks: ["de-CH", "de-DE", "en-GB"] },
    frFR: { language: "french", country: "france", code: 'fr', tag: "fr-FR", fallbacks: ["fr-CH", "en-GB"] },
    frCH: { language: "french", country: "switzerland", code: 'fr', tag: "fr-CH", fallbacks: ["fr-FR", "en-GB"] },
    itIT: { language: "italian", country: "italy", code: 'it', tag: "it-IT", fallbacks: ["it-CH", "en-GB"] },
    itCH: { language: "italian", country: "switzerland", code: 'it', tag: "it-CH", fallbacks: ["it-IT", "en-GB"] }
}
Reflect.defineProperty(Languages, 'toArray', {
    enumerable: false,
    get: () => () => Object.values(Languages),
})
