import { Memoized, Shared, Stream } from '@prospective/streamliner'
import { RemoteData, Retryable } from '@lib/remote_data/remote-data.js'
import { tryCatch } from '@prospective/pms-js-utils'
import JobBoosterService from '@services/job_booster_service.js'
import { Logger } from '@modules/logging/logger.js'

const logger = Logger('ProAnalyticsService')

const getOrganizationStructure =
    Stream(async function* () {
        yield RemoteData.pending()
        const [error, result] = await tryCatch(JobBoosterService.getHierarchyStructure)()
        if (error) {
            const { logNumber } = logger.error.withError(error, 'Failed to load ProAnalytics organization structure')
            throw RemoteData
                .error(locale => locale('proAnalytics.loadingOrganizationStructureError', { logNumber }))
                .logNumber(logNumber)
                .cause(error)
        }
        return RemoteData.success().setValue(result)
    })
    .compose(Shared)
    .compose(Retryable)
    .compose(Memoized)

export const ProAnalyticsService = { getOrganizationStructure }
